import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import MainLayout from "../components/layout/main";
import SEO from "../components/SEO";
import { Box } from "@chakra-ui/core";
import { MainHeading } from "../components/headings";
import PlaceList from "../components/places/placeList";
import { useRegisterScreen } from "../util/firebaseFunctions"

export default ({ data }) => {
  const intl = useIntl();
  useRegisterScreen('Delivery');
  const title = intl.formatMessage({ id: 'homeservice.title' });
  return (
    <MainLayout>
      <SEO
        description={intl.formatMessage({ id: 'homeservice.description' })}
        pathname="/home-service"
        title={title}
        type="category"
      />
      <Box>
        <MainHeading textAlign="center" mt={8}>
          {title}
        </MainHeading>
        <PlaceList data={data} subtitle />
      </Box>
    </MainLayout>
  );
};

export const query = graphql`
  query {
    allPlace(filter: {delivery: {eq: true}}) {
      nodes {
        id
        name
        name_en
        membership
        delivery
        photos {
          reference
        }
        fields {
          slug
        }
        categories {
          name
          name_en
          id
          fields {
            slug
          }
        }
      }
    }
  }
`;
